import { useState } from 'react'
import classNames from "classnames";
import Container from './Container';
import { ChevronDownIcon, ChevronUpIcon, ArrowNarrowRightIcon, CheckIcon } from '@heroicons/react/outline'
import newspaperImage from '../assets/sample_photos/newspaper.jpg';
import carImage from '../assets/sample_photos/car.jpg';
import festivalImage from '../assets/sample_photos/festival.jpg';
import coffeeImage from '../assets/sample_photos/coffee.jpg';
import airportImage from '../assets/sample_photos/airport.jpg';


import whiteLogo from '../assets/logo/name_white.svg';

const BuyPanelDropdown = ({ first=false, title, text }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className={first ? "border-2 border-white p-3 text-lg cursor-pointer" : "border-2 border-white p-3 text-lg cursor-pointer border-t-0" } onClick={() => setOpen(!open)}>
        <div className="font-medium tracking-wide">{title}<div className="float-right">{open ? <ChevronUpIcon className="h-6" /> : <ChevronDownIcon className="h-6" />}</div></div>
      </div>
      { open ? (
        <div className="border-2 border-t-0 border-white p-3 text-lg font-light">
          <div>
            {text.split("\n").map(line => <>{line}<br/></>)}
          </div>
        </div>
        ) : null}
    </>
  )
}

const ImageViewer = ({ }) => {
  const images = [
    airportImage,
    newspaperImage,
    carImage,
    festivalImage,
    coffeeImage
  ]

  const [selectedImage, setSelectedImage] = useState(0);

  const selectImage = (idx) => setSelectedImage(idx);

  return (<>
    <div className="flex">
      <div className="bg-black">
        <div className="flex flex-col space-y-3 pr-7">
            { images.map((imageUrl, idx) => (
              <div className={classNames("pb-2 border-b-2 hover:border-white", {
                  "border-white": idx === selectedImage,
                  "border-gray-600": idx !== selectedImage})}>
                <img
                  className={classNames("px-1 cursor-pointer hover:opacity-100", (idx === selectedImage ? null : "opacity-90"))}
                  src={imageUrl}
                  onClick={() => selectImage(idx)}
                  width="65px"
                />
              </div>
              ))}
          </div>
      </div>
      <div className="bg-black flex-1">
        <img
          src={images[selectedImage]}
          className="w-full"
        />
      </div>
    </div>
  </>);
}

const Sizes = ({sizes, selected, setSelected}) => {
  return (<>
    <div className="w-full border border-white border-gray-500">
      <div className="uppercase text-gray-500 text-md px-3 py-1">Sizes</div>
    </div>
    <div className="w-full border border-t-0 border-white border-gray-500">
      <div className="flex space-x-5 py-2 px-3">
        { sizes.map(size => 
          <div>
            <div className="flex flex-col space-y-1 cursor-pointer" onClick={() => setSelected(size)}>
              <div className="w-full text-center text-gray-500 text-xs pt-1">M</div>
              <div className={classNames("w-full text-center text-gray-200 px-1.5 py-1 rounded-full hover:bg-gray-700", {
                "bg-gray-700": selected === size
              })}>{size}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  </>);
}

export default function LandingBuySection() {
  const stock = {29: true, 30: true, 32: false, 34: true};
  const [selected, setSelected] = useState(null);

  const [email, setEmail] = useState(null);
  const [submitEmail, setSubmitEmail] = useState(false);

  const submitNotifyForm = (e) => {
    // TODO: Submit email
    console.log("Submit email: " + email);
    setSubmitEmail(true);
    e.preventDefault();
    return false;
  }

  return (
    <div className="flex mx-0 w-full bg-black text-white">
        <div className="flex-1"></div>
        <div className="max-w-6xl w-full">
          <Container>
          <div className="grid mx-0 w-full grid-cols-1 sm:grid-cols-2">
            <div className="pr-0 pt-20 pb-10">
              <ImageViewer />
            </div>
            <div className="ml-3 px-2 sm:px-6 sm:pt-20 lg:px-8">
              <div className="pl-11">
                <div className="text-left text-5xl mb-5">
                  <img src={whiteLogo} width="277px" />
                </div>
                <div className="text-left uppercase text-gray-500 text-md mb-9 font-semibold">Jean Drop #01</div>
                <div className="text-left text-gray-500 text-2xl mb-11">Your Last Pair of Jeans.</div>
                <Sizes selected={selected} setSelected={setSelected} sizes={Object.keys(stock)} />
                { selected && !stock[selected] ? (
                  <>
                    <div className="text-left text-gray-500 font-semibold tracking-normal text-md mt-11 mb-4">Size {selected} is out of stock</div>
                    <form onSubmit={submitNotifyForm}>
                      <div className="flex border border-white w-4/5">
                        { submitEmail ? (
                          <div className="px-3 py-2 uppercase">We will email you when we restock</div>
                        ) : (
                          <>
                            <div className="flex-1">
                              <input
                                  type="text"
                                  name="email"
                                  value={email}
                                  onChange={e => setEmail(e.target.value)}
                                  id="email"
                                  className="w-full text-white items-center justify-center px-4 py-2 text-base rounded-sm placeholder-gray-500 bg-black"
                                  placeholder="Email address"
                                />
                            </div>
                            <div className="pl-3 pr-5 pt-2"><input className="bg-black uppercase cursor-pointer" type="submit" value="Notify me"/></div>
                          </>
                        )}
                      </div>
                    </form>
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      className="mt-10 inline-flex items-center px-10 py-3 uppercase rounded-full border border-transparent shadow-sm text-2xl font-medium text-black bg-white hover:bg-gray-300"
                    >
                      Buy Now
                      <span className="ml-14 -mr-4 w-7"><ArrowNarrowRightIcon /></span>
                    </button>
                    <div className="text-left text-gray-500 text-sm mt-2 mb-11">
                      <CheckIcon className="h-5 inline mb-1 mr-1" />
                      Order now. Expected delivery Dec. 2021
                    </div>
                  </>
                ) }
              </div>
            </div>
          </div>
          <div className="grid mx-0 w-full grid-cols-1 text-white">
            <BuyPanelDropdown first={true} title={"Materials"} text={"Close your eyes. Think \"jeans.\" Now open. Presido… With a classic straight leg and iconic styling, they’re literally the blueprint for every pair of jeans in existence.\n\nThe best fitting jean \nThe original straight fit jean\nAll-American style\nA blank canvas for self-expression"} />
            <BuyPanelDropdown title={"Production"} text={"Close your eyes. Think \"jeans.\" Now open. Presido… With a classic straight leg and iconic styling, they’re literally the blueprint for every pair of jeans in existence.\n\nThe best fitting jean \nThe original straight fit jean\nAll-American style\nA blank canvas for self-expression"} />
          </div>
        </Container>
      </div>
      <div className="flex-1"></div>
    </div>
  )
}