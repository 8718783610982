import { useState, useRef, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationIcon } from '@heroicons/react/outline'
import {Helmet} from "react-helmet";
import axios from 'axios'
import { MenuIcon, XIcon, ArrowNarrowRightIcon } from '@heroicons/react/outline'
import LandingBuySection from './components/LandingBuySection';
import FullWidthImageSection from './components/FullWidthImageSection';
import MultipleImageSection from './components/MultipleImageSection';
import ImageAndTextSection from './components/ImageAndTextSection';
import ManifestoSection from './components/ManifestoSection';
import TickerSection from './components/TickerSection';
import TextAndButtonSection from './components/TextAndButtonSection';
import Dropdown from './components/Dropdown';
import Container from './components/Container';
import ImageWithCirclingText from './components/ImageWithCirclingText';
import whiteNameLogo from './assets/logo/presidio_jeans_white.png';
import madeInCaliforniaLogo from './assets/logo/made_in_california_white.svg';
import instagramLogo from './assets/social_buttons/instagram.svg';
import facebookLogo from './assets/social_buttons/facebook.svg';
import twitterLogo from './assets/social_buttons/twitter.svg';
import poolImage from './assets/sample_photos/pool.jpg';
import sittingVideo from './assets/videos/sittinglong.mp4';
import orderTodayCircle from './assets/order-today-eye-candy.svg';
import './App.css';

import nameLogo from './assets/logo/full_logo_black.png';

export default function PreOrder() {
  const buySectionRef = useRef(null);

  const scroll = (ref) => {
    ref.current.scrollIntoView({behavior: 'smooth'});
    return false;
  }

  const [submit, setSubmit] = useState(false);
  const [email, setEmail] = useState(null);
  const [showPurchasePopUp, setShowPurchasePopUp] = useState(false);
  const [selectedSize, setSelectedSize] = useState(null);

  const cancelButtonRef = useRef(null);

  const submitEmail = () => {
    axios.post("https://hook.integromat.com/7ijcd8hd5gvv3d9s1qqbpkkkx67rdrek", {
      "email": email
    })
    setSubmit(true);
    return false;
  }

  const modal = (
    <Transition.Root show={showPurchasePopUp} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setShowPurchasePopUp}>
        <div className="flex items-end justify-center min-h-screen sm:pt-4 sm:px-4 sm:pb-20 text-center sm:block w-full p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block w-full align-bottom bg-black sm:rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full min-h-screen sm:min-h-0">
              <div className="bg-black px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="text-gray-600 pb-8">
                  <XIcon className="float-right cursor-pointer h-5 w-5" aria-hidden="true" onClick={() => setShowPurchasePopUp(false)} ref={cancelButtonRef} />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 ">
                  <div className="mb-1 text-lg text-gray-400 text-left">Size</div>
                  <div className="text-lg text-gray-400 text-left">
                    <Dropdown onSelect={setSelectedSize} />
                  </div>
                  <div className="pt-6 mb-2 text-lg text-gray-400 text-left">Shipping</div>
                  <div className="text-md text-gray-400 text-left px-4 pt-2 pb-8 rounded-md border-solid border border-gray-500">
                    Orders will ship within 1 week of purchase.
                  </div>
                  <button
                    type="button"
                    className={`${ selectedSize ? 'text-white' : 'text-gray-500' }
                      inline-flex items-center rounded-full mt-10 px-10 py-4 border border-transparent shadow-sm text-md md:text-xl font-medium bg-gray-700 hover:bg-gray-600`}
                    onClick={() => window.location.href = selectedSize.checkoutUrl}
                  >
                    Order now
                  </button>
                  <div className="text-md text-gray-500 text-center mt-2 px-4 py-2">
                    Limited to 1 pair per customer.
                  </div>
                </div>
            </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
    );

  return (
    <>
      <Helmet>
        <title>P-Jeans - The Perfect Jeans. Re-invented for modernity. Order Now.</title>
      </Helmet>
      <div className="mx-0 px-0 w-full body-font text-black">
        <div className="flex mx-0 w-full">
          <div className="flex-1"></div>
          <div className="md:max-w-5xl w-full">
            <Container>
              <div className="flex flex-row px-6 w-full pt-5">
                <div className="flex-1 mx-0"><img src={nameLogo} className="logo-sm sm:logo" /></div>
                <div className="flex mx-0 mt-5 sm:mt-0 md:pr-5 font-light text-xs text-gray-500 text-lg sm:text-sm">Batch #0</div>
              </div>
              <div className="flex w-full px-5 pb-8 sm:pb-0 pt-16 sm:pt-8 md:pt-16 text-center">
                <div className="w-full">
                  <div className="px-10 text-6xl md:text-8xl mb-12 sm:mb-16 font-normal tracking-tighter" style={{lineHeight: '0.85'}} >Arrogantly <span className="script-font">high </span> <br/>standards</div>
                  <button
                    type="button"
                    className="inline-flex items-center rounded-full md:ml-2 px-8 py-3 md:py-4 border border-transparent shadow-sm text-lg sm:text-md md:text-xl font-medium text-white bg-black hover:bg-gray-900"
                    onClick={() => setShowPurchasePopUp(true)}
                  >
                    Order Now
                    <span className="ml-3 -mr-1 h-5 w-5"><ArrowNarrowRightIcon /></span>
                  </button>
                  <div className="text-xs md:text-md mt-3 mb-0 sm:mb-15 text-gray-500 uppercase font-medium tracking-normal"><span className="text-black">(Highly)</span> Limited Early Release</div>
                  { modal }
                </div>
              </div>
            </Container>
          </div>
          <div className="flex-1"></div>
        </div>
        <div className="flex mx-0 mt-36 sm:mt-40 md:pb-12 w-full bg-black text-white">
          <div className="flex-1"></div>
          <div className="hidden md:block">
            <div className="max-w-6xl -mt-28 preorder-video">
              <ImageWithCirclingText video={sittingVideo} textOnClick={() => scroll(buySectionRef)} />
            </div>
          </div>
          <div className="block md:hidden">
            <div className="max-w-6xl -mt-28 preorder-video-sm">
              <ImageWithCirclingText video={sittingVideo} textOnClick={() => scroll(buySectionRef)} />
            </div>
          </div>
          <div className="flex-1"></div>
        </div>
        <TickerSection bgWhite={true} />
        <div className="flex mx-0 w-full bg-black">
          <div className="flex-1"></div>
          <div className="max-w-6xl w-full">
            <Container>
              <div className="w-full pt-8 sm:pt-20 pb-12 sm:pb-0 mx-0">
                <img className="mx-5 sm:mx-auto" width="215" src={whiteNameLogo} />
              </div>
              <div className="flex mx-0 w-full">
                <div className="sm:flex-1"></div>
                <div className="max-w-s mx-5 sm:px-8 sm:pr-20">
                  <a href="https://www.instagram.com/pjeansdenim">
                    <button
                        type="button"
                        className="social-icon-mobile sm:social-icon px-2 py-2 mr-8 sm:mr-4 border rounded-full border-transparent bg-white hover:bg-gray-300 focus:outline-none"
                      >
                        <img className="mx-auto my-auto" src={instagramLogo} />
                    </button>
                  </a>
                  {/*<a href="https://facebook.com/presidiojeans">
                    <button
                        type="button"
                        className="social-icon-mobile sm:social-icon px-2 py-2 mr-8 sm:mr-4 border rounded-full border-transparent bg-white hover:bg-gray-300 focus:outline-none"
                      >
                        <img className="mx-auto my-auto" src={facebookLogo} />
                    </button>
                  </a>
                  <a href="https://twitter.com/PresidioJeans">
                    <button
                        type="button"
                        className="social-icon-mobile sm:social-icon px-2 py-2 border rounded-full border-transparent bg-white hover:bg-gray-300 focus:outline-none"
                      >
                        <img className="mx-auto my-auto" src={twitterLogo} />
                    </button>
                  </a>*/}
                </div>
                <div className="sm:flex-1 md:flex-none"></div>
              </div>
            </Container>
          </div>
          <div className="flex-1"></div>
        </div>
        <div className="flex mx-0 w-full bg-black">
          <div className="flex-1"></div>
          <div className="max-w-6xl w-full">
            <Container>
              <div className="flex flex-wrap mt-12 sm:mt-0 mx-0 w-full">
                <div className="flex-1 sm:ml-auto sm:px-6 lg:px-8">
                  <div className="text-white mx-5 sm:mx-0 mb-3 text-2xl sm:text-md">Stay up to date</div>
                  <div className="flex border border-white mx-5 sm:mx-0 sm:w-4/5" style={{minWidth: '200px', maxWidth: '500px'}}>
                    <div className="flex-1">
                      { !submit ? (
                        <input
                            type="text"
                            name="email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            id="email"
                            className="w-full text-white items-center justify-center px-4 py-2 text-lg sm:text-base rounded-sm placeholder-gray-500 bg-black"
                            placeholder="Email address"
                          />
                        ) : (
                          <div className="w-full text-gray-500 items-center justify-center px-4 py-2 text-lg sm:text-base rounded-sm placeholder-gray-500 bg-black">Welcome to the club!</div>
                        )
                      }
                    </div>
                    <div className="pl-3 pr-5 pt-3 sm:pt-2 text-white">{ !submit ? <input className="bg-black uppercase cursor-pointer" type="submit" onClick={submitEmail} value="Subscribe"/> : null }</div>
                  </div>
                </div>
                <div className="flex-1 px-5 mt-12 sm:mt-12">
                    <div className="flex flex-col sm:flex-row sm:space-x-16 text-white text-2xl sm:text-lg">
                      {/*<div className="flex-1 whitespace-nowrap">About</div>*/}
                      {/*<div className="flex-1 whitespace-nowrap">FAQs</div>*/}
                      <div className="flex-1 whitespace-nowrap"></div>
                      <div className="sm:pr-20 whitespace-nowrap"><a href="mailto:hello@p-jeans.com">Contact Us</a></div>
                      {/*<div className="flex-1 whitespace-nowrap">Shipping & Returns</div>*/}
                    </div>
                  </div>
              </div>
            </Container>
          </div>
          <div className="flex-1"></div>
        </div>
        <div className="flex mx-0 w-full bg-black">
          <div className="flex-1"></div>
          <div className="max-w-6xl w-full">
            <Container>
              <div className="mx-0 w-full pt-2 sm:pt-8">
                <div className="flex flex-wrap">
                  <div className="flex-1 mb-11 ml-5 sm:ml-auto sm:px-6 lg:px-8">
                    <div className="hidden sm:block text-white text-xl pb-2"><a href="https://www.instagram.com/pjeansdenim">@pjeansdenim</a></div>
                    <img src={madeInCaliforniaLogo} style={{height: '15px'}} />
                  </div>
                  <div className="flex-1"></div>
                </div>
              </div>
              <div className="mx-0 w-full pt-2 sm:pt-8">
                  <div className="text-xs text-right text-gray-300 pb-2 pr-3 mb-2 md:pr-20">© 2023 P-Jeans. All rights reserved.</div>
              </div>
            </Container>
          </div>
          <div className="flex-1"></div>
        </div>
      </div>
    </>
  )
}