import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Landing from './Landing';
import PreOrder from './PreOrder';

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/about"><Landing /></Route>
        <Route path="/fullRelease"><Landing /></Route>
        <Route path="/"><PreOrder /></Route>
      </Switch>
    </Router>
  )
}