import { useState } from 'react'
import classNames from "classnames";
import Container from './Container';

export default function TextAndButton() {
  return (
    <div className="flex mx-0 w-full">
      <div className="flex-1"></div>
      <div className="max-w-6xl w-full">
        <Container>
          <div className="flex mx-0 w-full">
            <div className="flex-1 ml-auto px-2 sm:px-6 lg:px-8">
              <div className=" pt-24">
                <div className="text-left text-5xl mb-11">Order <span className="script-font">today</span></div>
              </div>
            </div>
            <div className="max-w-s pr-0 mx-5 px-8 pt-24 pb-10 pr-20">
              <button
                  type="button"
                  className="w-full inline-flex items-center px-6 py-3 my-3 border border-transparent shadow-sm text-base font-medium text-white bg-black hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Order today
                <span className="ml-3 -mr-1 h-5 w-5">-></span>
              </button>
              <div className="text-base">"The Jeans just have swagger"</div>
            </div>
          </div>
        </Container>
      </div>
      <div className="flex-1"></div>
    </div>
  )
}