import { useState } from 'react'
import classNames from "classnames";
import Container from './Container';
import ImageWithCirclingText from './ImageWithCirclingText';
import { ArrowNarrowRightIcon} from '@heroicons/react/outline'
import deskImage from '../assets/sample_photos/desk.jpg';


export default function ImageAndTextSection({ textCircleClick }) {
  return (
    <div className="flex mx-0 w-full bg-black text-white pb-16">
      <div className="flex-1"></div>
      <div className="max-w-6xl w-full">
        <Container>
          <div className="grid mx-0 w-full grid-cols-1 sm:grid-cols-2">
            <div className="ml-auto px-2 sm:px-6 sm:pt-20 lg:px-8 lg:mr-5">
              <div className="pl-11 pt-8 ">
                <div className="text-left text-7xl font-medium tracking-tight mb-5">Feel good & walk <span className="script-font" style={{lineHeight: '10px'}}>with</span> swagger</div>
                <div className="text-left text-2xl font-light mb-11 leading-normal">
                  Lorem ipsum dolor sit ame consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi.
                </div>
                <button
                  type="button"
                  className="inline-flex items-center px-10 py-5 ml-2 uppercase rounded-full border border-transparent shadow-sm text-2xl font-medium text-black bg-white hover:bg-gray-200"
                  onClick={textCircleClick}
                >
                  Order today
                  <span className="ml-5 -mr-4 w-7"><ArrowNarrowRightIcon /></span>
                </button>
              </div>
            </div>
            <div className="pr-0 pl-8 pt-20 pb-10">
              <ImageWithCirclingText image={deskImage} textOnClick={textCircleClick} />
            </div>
          </div>
        </Container>
      </div>
      <div className="flex-1"></div>
    </div>
  )
}