import { useState } from 'react'
import classNames from "classnames";

import kanyeImage from '../assets/sample_photos/kanye.jpg';

import whiteLogo from '../assets/logo/name_white.svg';
import orderTodayCircle from '../assets/order-today-eye-candy.svg';

export default function ImageWithCirclingText({ image, video, textOnClick }) {
  if (video) {
    return (
      <>
        <div className="hidden md:block">
        <div
          className="w-full" style={{height: "700px", overflow: "hidden", display: "block"}}>
          <video autoPlay loop muted playsInline >
            <source src={video} type="video/mp4" />
          </video>
          <img 
            className="mx-auto items-center pointer relative"
            onClick={textOnClick}
            style={{marginTop: "-700px", width: "370px", animation: "rotation 18s infinite linear"}}
            src={orderTodayCircle} 
            />
        </div>
        </div>
        <div className="block md:hidden">
        <div
          className="w-full" style={{height: "650px", overflow: "hidden", display: "block"}}>
          <video autoPlay loop muted playsInline >
            <source src={video} type="video/mp4" />
          </video>
          <img 
            className="mx-auto items-center pointer relative"
            onClick={textOnClick}
            style={{marginTop: "-480px", width: "250px", animation: "rotation 18s infinite linear"}}
            src={orderTodayCircle} 
            />
        </div>
        </div>
      </>
      )
  }
  return (
    <div
      className="w-full grid"
      style={{backgroundImage: "url(" + image + ")", backgroundSize: "cover", backgroundPosition: "center", height: '650px'}}
    >
      <img 
        className="mx-auto my-auto items-center pointer"
        onClick={textOnClick}
        style={{width: "300px", animation: "rotation 18s infinite linear"}}
        src={orderTodayCircle} 
        />
    </div>
  )
}