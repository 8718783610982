import { useState } from 'react'
import classNames from "classnames";
import Container from './Container';

import pchImage from '../assets/sample_photos/pch.jpg';
import girlImage from '../assets/sample_photos/girl.jpg';
import weddingImage from '../assets/sample_photos/wedding.jpg';

export default function MultipleImageSection() {
  return (
    <div className="flex mx-0 w-full">
      <div className="py-20 flex-1 bg-black"></div>
      <div className="py-20 bg-black max-w-6xl w-full">
        <Container>
          <div className="flex">
            <div className="flex-1 bg-black">
              <div className="grid grid-cols-3 gap-3">
                <div
                  className="w-full"
                  style={{backgroundImage: "url(" + pchImage + ")", backgroundSize: "cover", backgroundPosition: "center", height: "350px"}}

                ></div>
                <div
                  className="w-full"
                  style={{backgroundImage: "url(" + girlImage + ")", backgroundSize: "cover", backgroundPosition: "center", height: "350px"}}

                ></div>
                <div
                  className="w-full"
                  style={{backgroundImage: "url(" + weddingImage + ")", backgroundSize: "cover", backgroundPosition: "center", height: "350px"}}

                ></div>
              </div>
            </div>
            <div className="bg-black w-60 flex flex-col" style={{height: '350px'}}>
              <div className="flex-grow">test</div>
              <div className="text-white text-base px-5 pb-5">"These Jeans just feel good <span className="script-font">all</span> the time."</div>
            </div>
          </div>
        </Container>
      </div>
      <div className="flex-1 bg-black"></div>
    </div>
  )
}