import { useState } from 'react'
import classNames from "classnames";
import Container from './Container';

export default function ManifestoSection() {
  return (
    <div className="flex mx-0 w-full">
      <div className="flex-1"></div>
      <div className="max-w-6xl w-full">
        <Container>
          <div className="mx-5 w-full py-32">
            <span className="text-7xl pl-64">
              Designed in the U.S by a group of successful <span className="script-font">Product Designers and Engineers</span>, they wanted to take the craftsmanship of building $100M+ online products and build something tangleble that has been around <span className="script-font">way longer then the internet</span>.
            </span>
          </div>
        </Container>
      </div>
      <div className="flex-1"></div>
    </div>
  )
}