import { useState, useRef } from 'react'
import {Helmet} from "react-helmet";
import { MenuIcon, XIcon, ArrowDownIcon } from '@heroicons/react/outline'
import LandingBuySection from './components/LandingBuySection';
import FullWidthImageSection from './components/FullWidthImageSection';
import MultipleImageSection from './components/MultipleImageSection';
import ImageAndTextSection from './components/ImageAndTextSection';
import ManifestoSection from './components/ManifestoSection';
import TickerSection from './components/TickerSection';
import TextAndButtonSection from './components/TextAndButtonSection';
import Container from './components/Container';
import ImageWithCirclingText from './components/ImageWithCirclingText';
import whiteNameLogo from './assets/logo/presidio_jeans_white.svg';
import madeInCaliforniaLogo from './assets/logo/made_in_california_white.svg';
import instagramLogo from './assets/social_buttons/instagram.svg';
import facebookLogo from './assets/social_buttons/facebook.svg';
import twitterLogo from './assets/social_buttons/twitter.svg';
import poolImage from './assets/sample_photos/pool.jpg';
import orderTodayCircle from './assets/order-today-eye-candy.svg';
import './App.css';

import nameLogo from './assets/logo/full_logo_black.svg';

export default function Landing() {
  const buySectionRef = useRef(null);

  const scroll = (ref) => {
    ref.current.scrollIntoView({behavior: 'smooth'});
    return false;
  }

  return (
    <>
      <Helmet>
        <title>Presidio Jeans - Buy the .....</title>
      </Helmet>
      <div className="mx-0 px-0 w-full body-font text-black">
        <div className="flex mx-0 w-full">
          <div className="flex-1"></div>
          <div className="max-w-6xl w-full">
            <Container>
              <div className="grid mx-0 w-full grid-cols-1 sm:grid-cols-2">
                <div className="pt-6 px-2 sm:px-6 lg:px-8">
                  <img src={nameLogo} style={{height: '75px'}} />
                  <div className="text-left pt-28">
                    <div className="text-7xl mb-11 font-medium tracking-tighter w-full">The new <span className="script-font">era</span><br/> of jeans</div>
                    <button
                      type="button"
                      className="inline-flex items-center uppercase rounded-full ml-2 px-10 py-5 border border-transparent shadow-sm text-2xl font-medium text-white bg-black hover:bg-gray-900"
                      onClick={() => scroll(buySectionRef)}
                    >
                      Available Now
                      <span className="ml-3 -mr-1 h-5 w-5"><ArrowDownIcon /></span>
                    </button>
                  </div>
                </div>
                <div className="bg-black pt-10 pr-10 pl-12 pb-10">
                  <div className="text-white text-right pb-10">
                    <div>Cart 0</div>
                  </div>
                  <ImageWithCirclingText image={poolImage} textOnClick={() => scroll(buySectionRef)} />
                </div>
              </div>
            </Container>
          </div>
          <div className="flex-1 bg-black"></div>
        </div>
        <div ref={buySectionRef}><LandingBuySection /></div>
        <FullWidthImageSection />
        <MultipleImageSection />
        <ImageAndTextSection textCircleClick={() => scroll(buySectionRef)} />
        <ManifestoSection />
        <TickerSection />
        <TextAndButtonSection />
        <div className="flex mx-0 w-full bg-black">
          <div className="flex-1"></div>
          <div className="max-w-6xl w-full">
            <Container>
              <div className="w-full pt-20">
                <img className="mx-auto" width="215" src={whiteNameLogo} />
              </div>
              <div className="w-full text-right">
                Test
              </div>
              <div className="flex mx-0 w-full">
                <div className="flex-1"></div>
                <div className="max-w-s pr-0 mx-5 px-8 pr-20">
                  <a href="https://www.instagram.com/presidiojeans">
                    <button
                        type="button"
                        style={{height: '40px', width: '40px'}}
                        className="px-2 py-2 mr-4 border rounded-full border-transparent bg-white hover:bg-gray-300 focus:outline-none"
                      >
                        <img className="mx-auto my-auto" style={{height: '24px'}} src={instagramLogo} />
                    </button>
                  </a>
                  <a href="https://facebook.com/presidiojeans">
                    <button
                        type="button"
                        style={{height: '40px', width: '40px'}}
                        className="px-2 py-2 mr-4 border rounded-full border-transparent bg-white hover:bg-gray-300 focus:outline-none"
                      >
                        <img className="mx-auto my-auto" style={{height: '24px'}} src={facebookLogo} />
                    </button>
                  </a>
                  <a href="https://twitter.com/PresidioJeans">
                    <button
                        type="button"
                        style={{height: '40px', width: '40px'}}
                        className="px-2 py-2 border rounded-full border-transparent bg-white hover:bg-gray-300 focus:outline-none"
                      >
                        <img className="mx-auto my-auto" style={{height: '24px'}} src={twitterLogo} />
                    </button>
                  </a>
                </div>
                <div className="flex-1 md:flex-none"></div>
              </div>
            </Container>
          </div>
          <div className="flex-1"></div>
        </div>
        <div className="flex mx-0 w-full bg-black">
          <div className="flex-1"></div>
          <div className="max-w-6xl w-full">
            <Container>
              <div className="flex flex-wrap mx-0 w-full">
                <div className="flex-1 ml-auto px-2 sm:px-6 lg:px-8">
                  <div className="text-white mb-3">Stay up to date</div>
                  <div className="flex border border-white w-4/5" style={{minWidth: '400px', maxWidth: '500px'}}>
                    <div className="flex-1">
                      <input
                          type="text"
                          name="email"
                          value={""}
                          onChange={e => console.log(e.target.value)}
                          id="email"
                          className="w-full text-white items-center justify-center px-4 py-2 text-base rounded-sm placeholder-gray-500 bg-black"
                          placeholder="Email address"
                        />
                    </div>
                    <div className="pl-3 pr-5 pt-2 text-white"><input className="bg-black uppercase cursor-pointer" type="submit" value="Subscribe"/></div>
                  </div>
                </div>
                <div className="flex-1 px-5 sm:mt-12">
                    <div className="flex flex-col sm:flex-row sm:space-x-16 text-white text-lg">
                      <div className="flex-1 whitespace-nowrap">About</div>
                      <div className="flex-1 whitespace-nowrap">FAQs</div>
                      <div className="flex-1 whitespace-nowrap">Contact Us</div>
                      <div className="flex-1 whitespace-nowrap">Shipping & Returns</div>
                    </div>
                  </div>
              </div>
            </Container>
          </div>
          <div className="flex-1"></div>
        </div>
        <div className="flex mx-0 w-full bg-black">
          <div className="flex-1"></div>
          <div className="max-w-6xl w-full">
            <Container>
              <div className="mx-0 w-full pt-8">
                <div className="flex flex-wrap">
                  <div className="flex-1 ml-auto mb-11 px-2 sm:px-6 lg:px-8">
                    <div className="text-white text-xl pb-2"><a href="https://www.instagram.com/presidiojeans">@presidiojeans</a></div>
                    <img src={madeInCaliforniaLogo} height="10" />
                  </div>
                  <div className="flex-1"></div>
                </div>
              </div>
            </Container>
          </div>
          <div className="flex-1"></div>
        </div>
      </div>
    </>
  )
}