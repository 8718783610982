import { useState } from 'react'
import classNames from "classnames";
import Ticker from 'react-ticker';
import Container from './Container';

export default function TickerSection({ bgWhite }) {
  return (
    <div className="mx-0 w-full">
      <div className={"py-5 sm:py-7 font-normal tracking-tight text-2xl" + (bgWhite ? " bg-white text-black" : " bg-black text-white")}>
        <Ticker speed={3}>
          {({ index }) => (
            <div className="flex" style={{whiteSpace: "nowrap"}}>
              <div className="px-8 sm:px-12 lowercase">Comfortable</div>
              <div className="px-4 sm:px-12 lowercase">🇺🇸</div>
              <div className="px-4 sm:px-12 lowercase">Made in USA</div>
              <div className="px-4 sm:px-12 lowercase">♻️</div>
              <div className="px-4 sm:px-12 lowercase">Eco-Friendly</div>
              <div className="px-4 sm:px-12 lowercase">🌱</div>
              <div className="px-4 sm:px-12 lowercase">Sustainable</div>
              <div className="px-4 sm:px-12 lowercase">😊</div>
              <div className="px-4 sm:px-12 lowercase">&nbsp;</div>
            </div>
          )}
        </Ticker>
      </div>
    </div>
  )
}