/* This example requires Tailwind CSS v2.0+ */
import { useState, Fragment, useRef } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const options = [
  {size: 28, available: false},
  {size: 29, available: true, checkoutUrl: "https://buy.stripe.com/bIYcPU0x73981S8fYZ"},
  {size: 30, available: true, checkoutUrl: "https://buy.stripe.com/28o9DIgw58tsdAQdQU"},
  {size: 32, available: true, checkoutUrl: "https://buy.stripe.com/bIY5nsa7HcJIfIYbIL"},
  {size: 34, available: true, checkoutUrl: "https://buy.stripe.com/7sIbLQ0x7bFEcwM4gi"},
];

export default function Dropdown({ onSelect }) {
  const [selected, setSelected] = useState(null);

  const updateSelection = (elem) => {
    onSelect(elem);
    setSelected(elem);
  }

  return (
    <Listbox value={selected} onChange={updateSelection}>
      <div className="relative mt-1">
        <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-gray-700 rounded-lg shadow-md cursor-pointer text-white text-md">
          <span className="block truncate">{selected ? selected.size : "Select your size"}</span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <ChevronDownIcon
              className="w-5 h-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-gray-700 rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none text-md">
            {options.map((item, itemIdx) => (
              <Listbox.Option
                key={itemIdx}
                className={({ active }) =>
                  `${active && item.available ? 'text-black bg-white' : 'text-white'}
                   ${item.available ? 'cursor-pointer' : 'text-gray-400 cursor-not-allowed' }
                        select-none relative py-2 pl-10 pr-4`
                }
                value={item}
              >
                {({ selected, active }) => (
                  <>
                    <span
                      className={`${
                        selected ? 'font-medium' : 'font-normal'
                      } ${
                        item.available ? '' : 'disabled'
                      } block truncate`}
                    >
                      {item.size}
                      {!item.available ? (
                        <span className={"pl-3"}>*sold out</span>
                      ) : null}
                    </span>
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );

  // return (
  //   <Menu as="div" className="w-full relative inline-block text-left">
  //     <div>
  //       <Menu.Button className="inline-flex text-left justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-gray-700 text-sm font-medium text-gray-100 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
  //         <div className="flex-1">Select your size</div>
  //         <div className="">
  //           <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
  //         </div>
  //       </Menu.Button>
  //     </div>

  //     <Transition
  //       as={Fragment}
  //       enter="transition ease-out duration-100"
  //       enterFrom="transform opacity-0 scale-95"
  //       enterTo="transform opacity-100 scale-100"
  //       leave="transition ease-in duration-75"
  //       leaveFrom="transform opacity-100 scale-100"
  //       leaveTo="transform opacity-0 scale-95"
  //     >
  //       <Menu.Items className="origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-gray-700 ring-1 ring-black ring-opacity-5 focus:outline-none">
  //         <div className="py-1">
  //           <Menu.Item>
  //             {({ active }) => (
  //               <a
  //                 href="#"
  //                 className={classNames(
  //                   'disabled cursor-not-allowed block px-4 py-2 text-sm'
  //                 )}
  //               >
  //                 28 *sold out
  //               </a>
  //             )}
  //           </Menu.Item>
  //           <Menu.Item>
  //             {({ active }) => (
  //               <a
  //                 href="#"
  //                 className={classNames(
  //                   active ? 'bg-gray-100 text-black' : 'text-white',
  //                   'block px-4 py-2 text-sm'
  //                 )}
  //               >
  //                 29
  //               </a>
  //             )}
  //           </Menu.Item>
  //           <Menu.Item>
  //             {({ active }) => (
  //               <a
  //                 href="#"
  //                 className={classNames(
  //                   active ? 'bg-gray-100 text-black' : 'text-white',
  //                   'block px-4 py-2 text-sm'
  //                 )}
  //               >
  //                 30
  //               </a>
  //             )}
  //           </Menu.Item>
  //           <Menu.Item>
  //             {({ active }) => (
  //               <a
  //                 href="#"
  //                 className={classNames(
  //                   active ? 'bg-gray-100 text-black' : 'text-white',
  //                   'block px-4 py-2 text-sm'
  //                 )}
  //               >
  //                 32
  //               </a>
  //             )}
  //           </Menu.Item>
  //           <Menu.Item>
  //             {({ active }) => (
  //               <button
  //                 type="submit"
  //                 className={classNames(
  //                   active ? 'bg-gray-100 text-black' : 'text-white',
  //                   'block w-full text-left px-4 py-2 text-sm'
  //                 )}
  //               >
  //                 34
  //               </button>
  //             )}
  //           </Menu.Item>
  //         </div>
  //       </Menu.Items>
  //     </Transition>
  //   </Menu>
  // )
}