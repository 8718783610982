import { useState } from 'react'
import classNames from "classnames";

import kanyeImage from '../assets/sample_photos/kanye.jpg';

import whiteLogo from '../assets/logo/name_white.svg';

export default function FullWidthImageSection() {
  return (
    <div className="flex mx-0 w-full bg-black">
      <div className="w-full my-20" style={{backgroundImage: "url(" + kanyeImage + ")", backgroundSize: "cover", backgroundPosition: "center", height: '550px'}}>
        <img className="mx-auto h-full" src={whiteLogo} width="450" />
      </div>
    </div>
  )
}